button {
  @extend .btn;
}

.btn {
  justify-self: flex-start;
  &-primary {
    color: #fff;
  }
  ul,
  ol {
    padding-left: 1rem;
    margin-bottom: 0;
    text-align: left;
  }
}
