// PT Sans
// https://fontsource.org/fonts/pt-sans/install
@include PTSans.faces(
  $subsets: (
    latin,
    latin-ext,
  ),
  $weights: all,
  $styles: all,
  $directory: '../font/'
);
// Dosis
// https://fontsource.org/fonts/dosis/install
@include Dosis.faces(
  $subsets: (
    latin,
    latin-ext,
  ),
  $weights: (
    600,
  ),
  $styles: all,
  $directory: '../font/'
);

body {
  font-family: 'PT Sans', $font-family-base;
  font-size: var(--font-size-body);
  font-variant-ligatures: common-ligatures;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-optical-sizing: auto;
}

@supports (font-variation-settings: normal) {
  body {
    font-variation-settings: 'wght' 400;
    font-feature-settings: 'calt', 'kern', 'dlig';
    font-synthesis: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-weight: 600;
  font-variation-settings: 'wght' 600;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Dosis Variable', $font-family-base;
  text-transform: uppercase;

  > a {
    text-decoration: none;
  }

  @include media-breakpoint-down(md) {
    hyphens: auto;
  }
}

p {
  hyphens: auto;
}

em,
i {
  font-style: italic;
}

b,
strong {
  font-weight: 700;
}

code {
  color: currentcolor;
}

h4,
h5 {
  text-wrap: balance; // Balance
}

h1 {
  font-size: clamp(2rem, 9vw, 2.2rem);
  margin-block-end: 1.2rem;
}

h2 {
  font-size: clamp(1.3rem, 6vw, 1.5rem);
  margin-block-end: 1rem;
}

h3 {
  font-size: clamp(1.1rem, 6vw, 1.3rem);
  margin-block-end: 0.75rem;
}

h4 {
  font-size: clamp(1rem, 5vw, 1.1rem);
  margin-block-end: 0.5rem;
}

h5 {
  font-size: clamp(0.9rem, 5vw, 1rem);
  margin-block-end: 0.4rem;
}

mark {
  background-color: var(--highlight-bg);
}
