body > header {
  gap: 1rem;

  > nav:first-child {
    grid-column: full;
    background-color: var(--background-navbar-top);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0.3rem 1rem;
    gap: 0.5rem;
  }

  > nav:nth-child(2) {
    background-color: var(--background-navbar-menu);
    grid-column: feature;
    padding: 0.3rem 1rem;
    display: flex;
    flex-wrap: wrap;

    .nav-link {
      color: var(--link);

      &.active {
        font-weight: bold;
      }
    }
  }

  > .alert {
    grid-column: feature;
  }
}

.offcanvas {
  &-header {
    display: flex;
    justify-content: space-between;
  }
}

// Versanstalter-Logo max. Größe
// benutzt im Navbar und Bearbeitung des Veranstalter
.veranstalter--logo {
  img {
    max-height: 70px;
    width: auto;
  }
}
