.dl-horizontal {
  @include make-row();

  dt,
  dd {
    @include make-col-ready();
  }
  dt {
    @include make-col(4);
  }
  dd {
    @include make-col(6);
  }
}

.aufgabenliste {
  list-style: none;
  padding-left: 0;

  > li {
    margin-block-end: 1rem;
  }
}
