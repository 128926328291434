@mixin flex() {
  display: flex;
}

@mixin flexColumn() {
  display: flex;
  flex-direction: column;
}

@mixin grid() {
  display: flex;
  flex-flow: column wrap;
  gap: var(--gap);

  @supports (grid-area: auto) {
    display: grid;
  }
}

@mixin gridLayout() {
  --content: min(70ch, 100% - var(--gap));
  --feature: minmax(0, 10rem);
  --full: minmax(var(--gap), 1fr);

  grid-template-columns:
    [full-start] var(--full)
    [feature-start] var(--feature)
    [content-start] var(--content) [content-end]
    var(--feature) [feature-end]
    var(--full) [full-end];
}

@mixin gridAuto() {
  flex-flow: row wrap;

  @supports (grid-area: auto) {
    grid-template-columns: repeat(auto-fill, minmax(var(--col), 1fr));
  }
}

@mixin gridAutoFit() {
  flex-flow: row wrap;

  @supports (grid-area: auto) {
    grid-template-columns: repeat(auto-fit, minmax(var(--col), 1fr));
  }
}

@mixin grid-reset {
  grid-column: -1/1;
}

.grid {
  @include grid;
}

.grid-auto {
  @include gridAuto;
}

.grid-reset {
  @include grid-reset;
}
