// https://components.ai/theme/NUAzFZDGRYCimd8ZFZA1?tab=editor
:root {
  // Grid
  --gap: 1rem;
  --col: 20ch;

  // https://web.dev/link-color/
  --link: #0093a8;
  --accent: #ef6763;

  accent-color: var(--accent);
}

// colors
:root {
  --border-color: #dddde2;
  --link-higher: #0093a8;
  --background: #fff;
  --foreground: #1f2023; // sg1
  --middleground: rgb(189 189 189);
  --background-higher: #e6f4f5;
  --foreground-higher: #0e0e0f;
  --foreground-lower: #303137;
  --shadow-color: hsl(240deg 1% 58%);
  --background-navbar-top: #e0e6e8; // top navbar
  --background-navbar-menu: #f9f9f9; // second nav in navbar
  --background-footer: #eff2f3; // footer
  --highlight-bg: hsla(188, 70%, 67%, 0.231);
}

// Bootstrap overrides
$primary: #0093a8;
$btn-white-space: nowrap;
$carousel-control-color: var(--link);
$card-bg: var(--background);
$card-header-bg: var(--background);
$offcanvas-color: var(--foreground);
$offcanvas-bg-color: var(--background-higher);
$transition-time: 0.6s;
$navbar-light-color: var(--foreground);
$navbar-light-hover-color: var(--foreground-higher);
$btn-close-color: #ccc;
