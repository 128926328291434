@include media-breakpoint-up(lg) {
  .login-page {
    main > header {
      background: transparent url('../img/intro-bg.webp') 50% 50% no-repeat;
      background-size: cover;
      display: flex;
      place-content: center;
      min-height: 300px;

      h1 {
        text-align: center;
        max-width: 55%;
        color: #fff;
      }
    }
  }
  .alert {
    outline: 1px solid var(--border-color);
  }
}
