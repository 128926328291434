.symbole-hilfe {
  dl {
    display: grid;
    gap: 1rem;

    dt {
      display: inline-flex;
      gap: 0.5rem;
      align-items: center;

      img {
        opacity: 0.6;
      }
    }
  }
}
