/* icons */
.icon {
  display: inline-block;
  vertical-align: middle;
  width: #{op.$size-5}; // 1rem
  height: #{op.$size-5};
  stroke: currentcolor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;

  a:has(&) {
    text-decoration: none;
  }

  .disabled a & {
    stroke: var(--middleground);
  }

  // Wagtail Userbar icon nicht geändert
  &.wagtail-action-icon {
    fill: unset;
    stroke: transparent;
  }

  // Sizes
  &--xs {
    width: #{op.$size-3};
    height: #{op.$size-3};
  }

  &--sm {
    width: #{op.$size-4};
    height: #{op.$size-4};
  }

  &--md {
    width: #{op.$size-6}; // 1.75rem
    height: #{op.$size-6}; // 1.75rem
  }

  &--lg {
    width: #{op.$size-7}; // 2rem
    height: #{op.$size-7}; // 2rem
  }

  &--xl {
    width: #{op.$size-8}; // 3rem
    height: #{op.$size-8}; // 3rem
  }

  // mark current checked theme in footer
  label:has(input:checked) & {
    fill: #{op.$gray-4};
  }
}
