html,
body {
  height: 100%;
}

body {
  display: grid;
  gap: var(--gap);
  grid-template-rows: auto 1fr auto;
  scrollbar-gutter: stable;
  height: 100%;

  > header,
  > footer,
  > main {
    @include grid;
    @include gridLayout;

    align-content: flex-start;

    > * {
      grid-column: feature;
    }
  }

  // Header & Footer full-width
  > header,
  > footer {
    gap: 0;

    & > * {
      grid-column: full;
      gap: 1rem;
    }
  }

  > main {
    .content--list {
      @include flexColumn;

      gap: var(--gap);
    }
  }

  > footer {
    nav {
      @include flex;
    }
  }

  // SVG sprite
  > svg {
    display: none;
  }
}

// Titel sind oft mit Symbol, auseinander halten:
main {
  h1,
  h2,
  h3,
  h4,
  h5 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn:first-child {
      margin-inline-start: auto;
    }
  }

  // pagination
  nav {
    justify-self: center;
  }
}
// ...aber nicht auf der Login-Seite (mit `mark`):
.login-page {
  h1,
  h2,
  h3 {
    justify-content: flex-start;
  }
}

nav > ul {
  margin-bottom: 0;
}

nav:has(> ul) {
  margin-block: 1rem;
}
