// Formulare
select,
textarea,
input[type='text'],
input[type='password'],
input[type='datetime'],
input[type='datetime-local'],
input[type='date'],
input[type='month'],
input[type='time'],
input[type='week'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='search'],
input[type='tel'],
input[type='color'] {
  @extend .form-control;
}

form {
  section {
    margin-bottom: 1rem;
  }

  label {
    @extend .form-label;
  }

  &.grid {
    button {
      place-self: center start;
      grid-column: -1/1;
    }
  }
}

::placeholder {
  color: var(--foreground);
}

.form-group {
  .form-control,
  .btn {
    margin-right: 0.2rem;
  }

  input,
  a.btn {
    line-height: 1.4;
  }
}

fieldset {
  @include flexColumn;

  gap: 1rem;
  border: 1px solid var(--border-color);
  padding: calc(var(--gap) / 2);
  border-radius: $border-radius;

  label {
    margin-right: 0.2rem;
  }

  legend {
    font-size: 1.75rem;
    font-weight: 500;
  }
}

// Validating
.errorlist {
  @extend .invalid-feedback;
  @extend .list-unstyled;
}

.invalid-feedback {
  display: block;
}

// Filter Formular
.filter {
  grid-column: feature;
  align-items: flex-end;
  padding: 1rem 0.5rem;
  border: 1px solid var(--border-color);
  border-radius: $border-radius;
}

// Choices.js
.choices {
  &__inner {
    font-size: unset;
  }

  &--multiple .choices__item {
    background-color: var(--bs-primary);
    border-color: darken($primary, 5%);
  }

  // #973
  &__list--single {
    .choices__button {
      display: none;
    }
  }
}

.helptext {
  @extend .text-muted;
}
