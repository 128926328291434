// a11y

/* Select all elements in the page and reduce or remove motion. */

/* Important note: This has no effect in browsers that don’t support prefers-reduced-motion */

.skip-link {
  position: absolute;
  top: 1rem;
  left: -9999rem;

  &:focus {
    width: 7rem;
    left: 1rem;
    z-index: 2;
    background-color: aliceblue;
  }
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

:focus-visible {
  outline: 0.1rem dotted;
}

:focus:not(:focus-visible) {
  outline: none;
}

// sections excluded from the tabindex
[tabindex="-1"]:focus {
  outline: none !important;
}

// links that are both focused AND hovered
a:focus:hover {
  outline: none;
}

// smooth scrolling
// https://schepp.dev/posts/smooth-scrolling-and-page-search/

@keyframes smoothscroll1 {
  from,
  to {
    scroll-behavior: smooth;
  }
}

@keyframes smoothscroll2 {
  from,
  to {
    scroll-behavior: smooth;
  }
}

html {
  animation: smoothscroll1 1s;
}

html:focus-within {
  animation-name: smoothscroll2;
  scroll-behavior: smooth;
}

svg {
  pointer-events: none;
}

// view transitions
// @media not (prefers-reduced-motion: reduce) {}
