.aan-status {
  display: inline-flex;
  border: 1px solid var(--border-color);
  padding: 0.2em;
  margin-right: 0.5em;
  margin-left: 0;
  border-radius: 3px;
  list-style: none outside;
  vertical-align: middle;

  &--1 {
    background-color: #{op.$yellow-6};
  }

  &--2 {
    background-color: #{op.$orange-8};
  }

  &--3 {
    background-color: #{op.$green-8};
  }
  li {
    display: inline-block;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    &:first-child {
      margin-right: 3px;
    }
  }
}
